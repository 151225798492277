import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home";
import About from "./components/about";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Contact from "./components/contact";
import { GlobalDataContext } from "./context/context";
import axios from "axios";
import Loader from "./components/global/Loader";

function Root() {
  const id = "632c73323977c3e94af31e4c";
  const [rpdata, setrpdata] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  //useEffect para peticion al servidor
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await axios({
          baseURL: "https://servidorpublico.herokuapp.com/api",
          url: `/paginas/${id}`,
          method: "get",
        });
        //se convierte la data en el objeto
        setrpdata(JSON.parse(JSON.stringify(response.data)));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    // Wait for 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

    return (

      isLoading ? 
      <Loader /> :

      <div className="App">
        <GlobalDataContext.Provider value={{ rpdata }}>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />}></Route>
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Router>
        </GlobalDataContext.Provider>
      </div>
    );
  }

export default Root;

ReactDOM.render(<Root />, document.getElementById("mingrand"));
